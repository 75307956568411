$primary-color: #3490dc;

.my-button {
  background-color: $primary-color;
  padding: 1rem 2rem;
  border-radius: 0.5rem;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
