// App wide styles
@import './tailwind.scss'; // Tailwind's base styles

html {
  font-size: 100%;
}

.troes-accounts-app {
  @apply text-slate-200;

  .main {
    @apply px-14 py-6;
  }

  .home {
    @apply xs:px-10 md:px-20;
  }

  .blue-btn {
    @apply btn bg-slate-300 text-troesBlue xs:w-full md:w-60 opacity-90 shadow-lg shadow-slate-800 hover:bg-troesBlue hover:text-slate-300;
  }

  .yellow-btn {
    @apply btn bg-yellow-500 text-white w-full opacity-90 shadow-lg shadow-slate-800 hover:bg-yellow-600;
  }
}